
import { getUnits, getLevels } from '../constants';
// Unlike in the backend, where card foils need to be taken into account, for deck conditions, we require at least 7 different cardIds per civ
// regardless of foil.

const foilMapper = ["silver", "gold"]

export const combineToUnique = (cards) => {
  const unique = {};
  
  for (let i = 0; i < cards.length; i++) {
    const cardKey = `${cards[i].cardId}-${cards[i].foil}`;

    if (unique[cardKey]) {
      const { foil: foilId, rarity } = unique[cardKey];
      const foil = foilMapper[foilId-1]
      unique[cardKey].repeated += 1;
      unique[cardKey].totalCP += cards[i].cp;
      unique[cardKey].cooldown = unique[cardKey].cooldown && cards[i].cooldown ? true : false;
      
      if (unique[cardKey].maxLevel < cards[i].level) {
        unique[cardKey].maxLevel = cards[i].level;
        unique[cardKey].tokenId = cards[i].tokenId;
      }

      // if (cardId == 7) {
      // console.log(getUnits().find(el => el.cardId == cardId).name, rarity, foil)
      // console.log(getLevels()[rarity - 1][foil][unique[cardKey].maxLevel])
      // }
      if (getLevels()[rarity - 1][foil][unique[cardKey].maxLevel] <= unique[cardKey].totalCP ) {
        unique[cardKey].canLevel = true 
      }
    } else {
      unique[cardKey] = { canLevel: false, totalCP: cards[i].cp ,repeated: 0, maxLevel: cards[i].level, ...cards[i] };
    }
  }

  return Object.values(unique);
};

export const combineToUniqueCardId = (cards) => {
  const unique = {};
  
  for (let i = 0; i < cards.length; i++) {
    const cardKey = cards[i].cardId;

    if (unique[cardKey]) {
      unique[cardKey].repeated += 1;
      if (unique[cardKey].maxLevel < cards[i].level) {
        unique[cardKey].maxLevel = cards[i].level;
        unique[cardKey].tokenId = cards[i].tokenId;
      }
    } else {
      unique[cardKey] = { repeated: 0, maxLevel: cards[i].level, ...cards[i] };
    }
  }

  return Object.values(unique);
};

export function addTypeAndColor(collection) {
  const units = getUnits()
  return collection.map(el => ({ ...el, type: units.find(unit => unit.cardId == el.cardId).type, color: units.find(unit => unit.cardId == el.cardId).color }))
}

export const addFilters = (cards, filters) => {
  const collectionWithDeckFilters = cards.map(el => ({ ...el, deckEnabled: filters.filter(filter => el.cardId == filter.cardId && el.foil == filter.foil).length > 0 ? false : true }))
  return collectionWithDeckFilters
}

export const addColors = (cards) => {
  const units = getUnits()
  return cards.map(card => ({ ...card, color: units.find(unit => unit.cardId == card.cardId).color }))
}

export const addLevels = (cards) => {

  const units = getUnits()
  const levels = getLevels()

  // Add level to each card based on burnt, rarity, and foil type
  return cards.map(card => {
    const match = units.find(el => el.cardId == card.cardId)
    const rarityIndex = match.rarity; // Adjust for zero-based index
    const foilType = card.foil === 1 ? 'silver' : 'gold';
    const levelProgression = levels[rarityIndex - 1][foilType];

    let level = 0;
    for (let i = 0; i < levelProgression.length; i++) {
      if (card.cp >= levelProgression[i]) {
        level += 1;
      } else {
        break;
      }
    }

    return { ...card, level, rarity: rarityIndex };
  });
}
