/* eslint-disable */


import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid2'; // Grid version 2
import {cdnUrl } from "../../constants.js";
import { cardFooterAStyle, overlayStyle, hoverButtonStyle } from "../New/Styles.jsx";
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

function LeftGrid({ 
  accountData, 
  sendMessage, 
  setIsSearching, 
  handleLogin, 
  address, 
  transakHandler, 
  buyFaithWithCryptoHandler,
  buyFaithWithFiatHandler,
  buyPacksWithCryptoHandler,
  wallet
}) {
  const navigate = useNavigate();
  const [tutorialIsHovered, setTutorialIsHovered] = useState(false);
  const [packsIsHovered, setPacksIsHovered] = useState(false);
  const [referralIsHovered, setReferralIsHovered] = useState(false);
  const [league, setLeague] = useState(null);
  const [faithIsHovered, setFaithIsHovered] = useState(false)
  const [elixyrIsHovered, setElixyrIsHovered] = useState(false)

  const theme = useTheme(); // Access the theme
  const cardImageAStyle = { 
    width: '100%', 
    height: 'auto', 
    display: 'block', 
    margin: 0
  }


  useEffect(() => {
    if (accountData) {
      console.log("accountData", accountData)
      if (accountData.elo < 1200) setLeague('bronze')
      else if (accountData.elo < 1400) setLeague('silver')
      else setLeague('gold')
    }
  }, [accountData])

  const practiseHandler = () => {
    if (!address) return handleLogin()
    setIsSearching(true)
    const msg = JSON.stringify({ type: 'playBot', data: {} });
    sendMessage(msg);
  }

  return (
    <>
    <Grid size={{ xs: 6 }}>
      <div 
        style={{ position: 'relative' }} 
        onMouseEnter={() => setFaithIsHovered(true)}
        onMouseLeave={() => setFaithIsHovered(false)}
      >
        <img 
          src={`${cdnUrl}/app/league/league-background.webp`} 
          style={{ ...cardImageAStyle, border: '1px solid', borderColor: '#937341', borderRadius: '8px 8px 0 0' }} 
        />

        <img
          src={`${cdnUrl}/app/shop/faith-animation-transparent-2.gif`}
          style={{
            position: 'absolute',
            top: '65%',
            left: '50%',
            transform: 'translate(-50%, -72%)', // Center both horizontally and vertically
            width: '50%',
            height: 'auto',
            objectFit: 'cover',
            opacity: 1
          }}
        />
        {faithIsHovered && 
          <>
            <div style={{ ...overlayStyle, zIndex: 1 }}></div> 
            <div style={{ 
              position: 'absolute', 
              top: '30%', 
              left: '50%', 
              transform: 'translate(-50%, -50%)', // Center the button container
              zIndex: 2 // Ensure buttons are on top of the overlay
            }}>
              <div style={{ position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}>
                <button
                  onClick={buyFaithWithFiatHandler}
                  style={{
                    ...hoverButtonStyle,
                    width: '180px',
                    zIndex: 2 // Ensure each button is above the overlay
                  }}
                >With credit card</button>
              </div>
              <div style={{ position: 'absolute', top: '70px', left: '50%', transform: 'translateX(-50%)' }}>
                <button
                  onClick={buyFaithWithCryptoHandler}
                  style={{
                    ...hoverButtonStyle,
                    width: '180px',
                    zIndex: 2 // Ensure each button is above the overlay
                  }}
                >with Crypto</button>
              </div>
            </div>
          </>
        }

      </div>
      <div style={cardFooterAStyle}>
        <p
          style={{ fontSize: '0.9rm' }}
        >BUY FAITH</p>
      </div>
    </Grid>

    <Grid size={{ xs: 6 }}>
      <div 
        style={{ position: 'relative' }}
        onMouseEnter={() => setPacksIsHovered(true)}
        onMouseLeave={() => setPacksIsHovered(false)}
      >
        <img 
          src={`${cdnUrl}/app/league/league-background.webp`} 
          style={{ ...cardImageAStyle, border: '1px solid', borderColor: '#937341', borderRadius: '8px 8px 0 0' }} 
        />
        <img
          src={`${cdnUrl}/app/shop/pack-transparent-2.gif`}
          style={{
            position: 'absolute',
            top: '65%',
            left: '50%',
            transform: 'translate(-50%, -72%)', // Center both horizontally and vertically
            width: '60%',
            height: 'auto',
            objectFit: 'cover',
            opacity: 1
          }}
        />

        {packsIsHovered && 
          <>
          <div style={overlayStyle}></div> 
          <button
            onClick={buyPacksWithCryptoHandler}
            style={{
              ...hoverButtonStyle
            }}
          >
            with USDT
          </button>
          </>
        }


      </div>
      <div style={cardFooterAStyle}>
        <p
          style={{ fontSize: '0.9rm' }}
        >BUY ALPHA PACKS</p>
      </div>
      
    </Grid>
    <Grid size={{ xs: 6 }}>
      <div
        style={{ position: 'relative' }} 
        onMouseEnter={() => setElixyrIsHovered(true)}
        onMouseLeave={() => setElixyrIsHovered(false)}
      >
        <img 
          src={`${cdnUrl}/app/league/league-background.webp`} 
          style={{ ...cardImageAStyle, border: '1px solid', borderColor: '#937341', borderRadius: '8px 8px 0 0' }} 
        />
        <img
          src={`${cdnUrl}/app/shop/elixyr-animation-transparent.gif`}
          style={{
            position: 'absolute',
            top: '55%',
            left: '50%',
            transform: 'translate(-50%, -72%)', // Center both horizontally and vertically
            width: '50%',
            height: 'auto',
            objectFit: 'cover',
            opacity: 1
          }}
        />
        {elixyrIsHovered && 
          <>
          <div style={overlayStyle}></div> 
          <button
            // onClick={() => navigate('/openpacks')}
            style={{
              ...hoverButtonStyle
            }}
          >Coming Soon</button>
          </>
        }
      <div style={cardFooterAStyle}>
        <p
          style={{ fontSize: '0.9rm' }}
        >BUY ELIXYR</p>
      </div>
      </div>
    </Grid>
    <Grid size={{ xs: 6 }}>
      <div
        style={{ position: 'relative' }} 
        onMouseEnter={() => setReferralIsHovered(true)}
        onMouseLeave={() => setReferralIsHovered(false)}
      >
        <img 
          src={`${cdnUrl}/app/league/league-background.webp`} 
          style={{ ...cardImageAStyle, border: '1px solid', borderColor: '#937341', borderRadius: '8px 8px 0 0' }} 
        />
        <img
          src={`${cdnUrl}/app/shop/beta-pack-animation-2.gif`}
          style={{
            position: 'absolute',
            top: '55%',
            left: '50%',
            transform: 'translate(-50%, -72%)', // Center both horizontally and vertically
            width: '60%',
            height: 'auto',
            objectFit: 'cover',
            opacity: 1
          }}
        />
        {referralIsHovered && 
          <>
          <div style={overlayStyle}></div> 
          <button
            // onClick={() => navigate('/referral')}
            style={{
              ...hoverButtonStyle,
              width: '180px',
              height: '45px',
              fontSize: '16px',
              color: '#EAC88C',
              fontFamily: 'Germania One',
              backgroundImage: `url(${cdnUrl}/landing/button.webp)`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              backgroundColor: 'rgba(0,0,0,0)',
              border: 'none'
            }}
          >Coming Soon</button>
          </>
        }
        <div style={cardFooterAStyle}>
          <Grid container size={{ xs: 12 }} alignItems="center" justifyContent="center">
            <Grid size={{ xs: 12 }} style={{ textAlign: 'center', color: theme.palette.text.secondary }}>
              <p
                style={{
                  fontSize: '0.9rem' 
                }}
              >BUY BETA PACKS</p>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
    </>
  )
}

export default LeftGrid;
