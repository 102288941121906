import { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { cdnUrl } from '../constants';

const BackgroundBox = styled(Box)({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: 'calc(100vh - 70px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const Logo = styled('img')({
  // minWidth: '40%',
  maxWidth: '30%',
  marginBottom: '20px', // Add some space between the logo and the buttons
});

const buttonStyle = {
  fontFamily: 'Germania One',
  fontSize: 'clamp(18px, 2vw, 24px)',
  fontWeight: '400'
}
// const lynexFaithLink = "https://app.lynex.fi/swap?outputCurrency=0x373d04a7f3594ff9011af5084587182c0056721e"

const Home = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <BackgroundBox>
      <Logo src={`${cdnUrl}/landing/main-logo.png`} alt="Lords Forsaken Logo" />
      <Grid container justifyContent="center" alignItems="center">
        <Grid size={{ xs: 12, md: 6 }} container justifyContent="center" sx={{ padding: '10px' }}>
          <Button 
            style={buttonStyle}
            component={Link} 
            className="image-button" 
            to="/battle"
          >Play Now</Button>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }} container justifyContent="center" sx={{ padding: '10px' }}>
          <Button 
            style={buttonStyle}
            href="https://www.nile.build/swap?from=0x373d04A7F3594FF9011Af5084587182C0056721e&to=0x46BF60BD1d8e61fE8975B4122224f8ED81C1164f" 
            target="_blank" 
            className="image-button"
            rel="noopener noreferrer" // for security reasons
          >
            Buy Card Packs
          </Button>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography sx={{ p: 2 }}>Coming soon!</Typography>
          </Popover>
        </Grid>
      </Grid>
    </BackgroundBox>
  );
};

export default Home;
