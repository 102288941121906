import { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Checkbox, Button } from '@mui/material';
import CallMergeIcon from '@mui/icons-material/CallMerge';
// import { wait } from "../../utils"
import { cdnUrl } from "../../constants.js"
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';


function CardsTable({ selected, setSelected, combineHandler, setTransferDialog, cards, address, foil, wallet }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cardsByFoil, setCardsByFoil] = useState(null);

  const properties = ['tokenId', 'level', 'cp', 'nonce'];
  const labels = ['Token Id', 'Level', 'Card Power', 'Nonce'];

  const capitalizeFirstChar = str => str.charAt(0).toUpperCase() + str.slice(1);

  useEffect(() => {
    console.log("**** CHANGE IN CARDS DETECTED ****")
    if (foil && cards) {
      console.log("foil", foil)
      const foilId = foil == 'silver' ? 1 : 2;
      setCardsByFoil(cards.filter(el => el?.foil == foilId))
    }
  }, [foil, cards])

  // useEffect(() => {
  //   console.log("cardsByFoil", cardsByFoil)
  // }, [cardsByFoil])

  useEffect(() => {
    // Logic to refresh the table when cardsByFoil changes
    console.log("**** selected ****", selected.map(tokenId => cards.find(el => el.tokenId == tokenId)));
  }, [selected]);


  const onCombineHandler = () => {
    combineHandler()
    setSelected([])
  }

  const onTransferHandler = () => {
    console.log("onTransferHandler", selected)
    setTransferDialog(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectRow = (tokenId) => {
    setSelected((prevSelected) => {
      // Create a new array from the previous state
      const newSelected = prevSelected.includes(tokenId)
        ? prevSelected.filter((id) => id !== tokenId)
        : [...prevSelected, tokenId];

      // Sort the array by the 'burnt' property in descending order
      return newSelected.sort((a, b) => {
        const cardA = cardsByFoil.find(card => card.tokenId === a);
        const cardB = cardsByFoil.find(card => card.tokenId === b);
        return cardA.nonce - cardB.nonce;
      });
    });
  };

  const isSelected = (tokenId) => selected.includes(tokenId);

  if (!address) {

    return (
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        {/*<Alert variant="outlined" severity="info">You need to login to see your cards</Alert>*/}
        <h1>You need to login to see your cards</h1>
      </Box>
    )
  }

  if (!cardsByFoil || cardsByFoil?.length == 0) {

    return (
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        {/*<Alert variant="outlined" severity="info">You need to login to see your cards</Alert>*/}
        <h1>You own none of this card</h1>
      </Box>
    )
  }

  return (
    <Box className="tableBox" sx={{ width: '100%', maxWidth: '500px' }}>
      
      <Box sx={{ padding: '20px' }}>
        <Button 
          className="image-button"
          style={{
            fontFamily: 'Poppins',
            fontWeight: '600',
            fontSpacing: '-2px',
            width: '200px',
            height: '50px',
            color: '#EAC88C',
            margin: '10px'
          }} 
          disabled={selected.length < 2} 
          startIcon={<CallMergeIcon />} 
          onClick={onCombineHandler}>Combine
        </Button>
        {
          wallet == "MetaMask" &&
          <Button 
            className="image-button"
            style={{
              fontFamily: 'Poppins',
              fontWeight: '600',
              fontSpacing: '-2px',
              width: '200px',
              height: '50px',
              color: '#EAC88C',
              margin: '10px'
            }} 
            disabled={selected.length !== 1} 
            startIcon={<SendIcon />} 
            onClick={onTransferHandler}>Transfer
          </Button>
        }
      </Box>
      
      <TableContainer sx={{ borderRadius: '16px', border: 'none' }}>
        <Table sx={{ backgroundColor: 'transparent' }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ borderBottom: '2px solid #937341', height: '60px' }}>
              <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'center', width: '50px' }}>
                Select
              </TableCell>
              <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'center', width: '50px' }}>
                Cooldown
              </TableCell>
              {labels.map((property) => (
                <TableCell key={property} sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'center', width: '50px' }}>
                  {capitalizeFirstChar(property)}
                </TableCell>
              ))}
              {
                wallet == "MetaMask" &&
                <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'center', width: '50px' }}>
                  <img src={`${cdnUrl}/misc/element.webp`} width="50"/>
                </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {cardsByFoil.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((card) => (
              <TableRow
                key={card.tokenId}
                sx={{ borderBottom: '1px solid #937341', height: '60px' }}
                selected={isSelected(card.tokenId)}
                onClick={() => handleSelectRow(card.tokenId)}
              >
                <TableCell align="center" sx={{ padding: '8px', textAlign: 'center' }}>
                  <Checkbox
                    checked={isSelected(card.tokenId)}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleSelectRow(card.tokenId);
                    }}
                  />
                </TableCell>
                <TableCell align="center" sx={{ padding: '8px', textAlign: 'center' }}>
                  { card.cooldown ? 'YES' : 'NO' }
                </TableCell>
                {properties.map((property) => (
                  <TableCell key={property} align="center" sx={{ padding: '8px', textAlign: 'center' }}>
                    {card[property]}
                  </TableCell>
                ))}
                {
                  wallet == "MetaMask" &&
                  <TableCell>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://element.market/assets/linea/0xfb1a2ee4625df29d36a984a38db7b7b8b8283d63/${card.tokenId}`}
                    >
                      <LinkIcon sx={{ color: '#EAC88C' }} /> {/* Set your desired color here */}
                    </a>
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={cards.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default CardsTable;
