/* eslint-disable */

import { useEffect, useState, Fragment } from 'react';
import Grid from '@mui/material/Grid2'; // Grid version 2
import { cdnUrl } from "../constants.js";
import Box from '@mui/material/Box';
import Ranking from "../components/Ranking/Ranking.jsx";
import TableTabs from "../components/New/TableTabs.jsx";
import LeftGrid from "../components/Shop/LeftGrid.jsx";
import RightGrid from "../components/Shop/RightGrid.jsx";
import { backgroundImgStyle } from "../components/New/Styles.jsx";
import Matchmaking from "../components/New/Matchmaking.jsx";
import Matchfound from "../components/New/Matchfound.jsx";
import HistoryTable from "../components/Menu/HistoryTable"
import UsernameModal from "../components/Username/UsernameModal.jsx";
import { nodeEnv } from "../constants";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Transak } from '@transak/transak-sdk';
import { useNavigate } from 'react-router-dom';

function Shop({
  address,
  wallet,
  setIsLoading,
  accountData,
  handleLogin
}) {
  const spacing = 5;
  const [activeTab, setActiveTab] = useState('Leaderboard');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate()

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const buyFaithWithCryptoHandler = () => {
    if (wallet == 'MetaMask') window.open('https://www.nile.build/swap?to=0x373d04A7F3594FF9011Af5084587182C0056721e', '_blank', 'noopener,noreferrer');
    else if (wallet == 'Kondor' || wallet == 'Konio') window.open('https://app.koindx.com/swap', '_blank', 'noopener,noreferrer');
    else handleLogin()
  }

  const buyFaithWithFiatHandler = () => {
    if (wallet == 'Kondor') alert('Onramp only available for EVM users')
  }

  const buyPacksWithCryptoHandler = () => {
    if (wallet == 'MetaMask') window.open('https://www.nile.build/swap?from=0x373d04A7F3594FF9011Af5084587182C0056721e&to=0x46BF60BD1d8e61fE8975B4122224f8ED81C1164f', '_blank', 'noopener,noreferrer');
    else if (wallet == 'Kondor' || wallet == 'Konio') navigate('/packs')
    else handleLogin()
  }

  const transakHandler = () => {
 	const transakConfig = {
	  apiKey: 'ace4a621-bbc3-4094-a7e1-1dcf7748627b', // (Required)
	  environment: Transak.ENVIRONMENTS.PRODUCTION, // (Required)
	  exchangeScreenTitle: 'BUY ETH',
	  network: 'linea',
	  fiatCurrency: 'USD',
	  fiatAmount: 10,
	  walletAddress: address
	};

	let transak = new Transak(transakConfig);

	transak.init();

	Transak.on('*', (data) => {
	  console.log(data);
	});

  }

  return (
    <Box>
      <div style={{
        backgroundColor: '#0A0A0C',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100%',
        zIndex: -1,
        filter: 'brightness(1.5)', // Adjust the brightness (1 is the original, 1.5 is 50% brighter)
      }}></div>

      <div style={{
        backgroundColor: '#0A0A0C',
        backgroundImage: `url("${cdnUrl}/battle-menu/background.png")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundSize: 'cover', // or 'contain' based on your needs
        position: 'absolute',
        top: '55%',
        left: 0,
        width: '100vw',
        height: '624px',
        zIndex: -1,
        filter: 'brightness(1.5)', // Adjust the brightness (1 is the original, 1.5 is 50% brighter)
      }}></div>

      <Grid 
        container 
        spacing={spacing}
        style={{
          minHeight: '50vh',
          maxWidth: '1500px', // Limit the width
          width: '70%',
          margin: '180px auto 0',
          padding: '20px'
        }}
      >

        <Grid size={{ xs: 12 }} >
          <img
            src={`${cdnUrl}/battle-menu/separator1.svg`}
            alt="divider"
            style={{ width: '100%', height: 'auto' }}
          />
        </Grid>
        
        {/* Left Grid */}

        <Grid size={{ xs: 12, md: 6 }}>
          <RightGrid 
            handleLogin={handleLogin}
            address={address}
          />
        </Grid>

        <Grid container size={{ xs: 12, md: 6 }} spacing={spacing}>
          <LeftGrid 
            accountData={accountData}
            handleLogin={handleLogin}
            address={address}
            transakHandler={transakHandler}
            buyFaithWithCryptoHandler={buyFaithWithCryptoHandler}
            buyPacksWithCryptoHandler={buyPacksWithCryptoHandler}
            buyFaithWithFiatHandler={buyFaithWithFiatHandler}
            wallet={wallet}
          />
        </Grid>
        <Grid size={{ xs: 12 }} >
          <img
            src={`${cdnUrl}/battle-menu/separator2.svg`}
            alt="divider"
            style={{ width: '100%', height: 'auto' }}
          />
        </Grid>

      </Grid>
    </Box>
  );
}

export default Shop;