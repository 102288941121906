import { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import { trimAddress } from "../../utils";
import { cdnUrl } from "../../constants.js";

const trimString = str => str.length > 12 ? str.slice(0, 12) + "..." : str;

const MyTable = ({ rows }) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5); // Max items per page

  if (!rows) return (<center><h1>Loading..</h1></center>);

  // Calculate displayed rows
  const displayedRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
    <h1 style={{ fontFamily: 'Germania One', color: '#937341' }}> Season #6 | Until 8th Feb 18:00 UTC </h1>
    <TableContainer sx={{ borderRadius: '16px', border: 'none' }}>
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: '2px solid #937341', height: '60px' }}>
            <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'left', width: '50px' }}>Rank</TableCell>
            <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'left', width: '300px' }}>Name</TableCell>
            <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'left' , width: '150px' }}>Network</TableCell>
            <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'left', width: '150px' }}>Elo</TableCell>
            <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'left', width: '150px' }}>Max Streak</TableCell>
            <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'left', width: '150px' }}>Battles</TableCell>
            <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'left', width: '150px' }}>Wins</TableCell>
            <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'right', width: '350px' }}>Rewards</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedRows.map((row, index) => (
            <TableRow key={index} sx={{ borderBottom: '1px solid #937341', height: '60px' }}>
            <TableCell sx={{ padding: '8px', textAlign: 'center' }}>
              <div style={{
                backgroundColor: '#937341',
                borderRadius: '8px',
                width: '32px', // Adjust width as needed
                height: '24px', // Adjust height as needed
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}>
                <span style={{
                  color: 'black', // Change this to whatever color you want for the text
                  fontSize: '15px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  letterSpacing: '-0.01em',
                }}>
                  {(index + 1) + rowsPerPage * page}
                </span>
              </div>
            </TableCell>
              <TableCell sx={{ padding: '8px', textAlign: 'left' }}>
                {row.name ? trimString(row.name) : trimAddress(row.address)}
              </TableCell>
              <TableCell sx={{ padding: '8px', textAlign: 'left' }}>
                {row.network === 'MetaMask' ? (
                  <img src={`${import.meta.env.VITE_CDN_URL}/landing/colabs/linea-gold.svg`} width="80" alt="Linea" />
                ) : (
                  <img src={`${import.meta.env.VITE_CDN_URL}/landing/colabs/koinos-gold.v2.png`} width="90" alt="Koinos" />
                )}
              </TableCell>
              <TableCell sx={{ padding: '8px', textAlign: 'left' }}>{row.elo}</TableCell>
              <TableCell sx={{ padding: '8px', textAlign: 'left' }}>{row.maxStreak}</TableCell>
              <TableCell sx={{ padding: '8px', textAlign: 'left' }}>{row.battles}</TableCell>
              <TableCell sx={{ padding: '8px', textAlign: 'left' }}>{row.wins}</TableCell>
              <TableCell sx={{ padding: '8px', textAlign: 'right' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}>
                  {row?.reward?.pack && (
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                        <span>x{row.reward.pack}</span>
                        <img src={`${cdnUrl}/packs/alpha-pack3.png`} width="20" height="25" alt="Faith Token" style={{ marginLeft: '5px' }} />
                        {row?.reward?.['gold-promo-card'] > 0 && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+</span>}
                    </div>
                  )}
                  {row?.reward?.['gold-promo-card'] > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                      <span>x{row.reward['gold-promo-card']}</span>
                      <img src={`${cdnUrl}/cards/webp/v3/gold/Medusa.webp`} width="25" alt="Medusa Gold" />
                      <img src={`${cdnUrl}/cards/webp/v3/gold/Cyclop.webp`} width="25" alt="Cyclop Gold" />
                      <img src={`${cdnUrl}/cards/webp/v3/gold/Nidhogg.webp`} width="25" alt="Nidhogg Gold" />
                    </div>
                  )}
                  {row?.reward?.['silver-promo-card'] > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                      <span>x{row.reward['silver-promo-card']}</span>
                      <img src={`${cdnUrl}/cards/webp/v3/silver/Medusa.webp`} width="25" alt="Medusa silver" />
                      <img src={`${cdnUrl}/cards/webp/v3/silver/Cyclop.webp`} width="25" alt="Cyclop silver" />
                      <img src={`${cdnUrl}/cards/webp/v3/silver/Nidhogg.webp`} width="25" alt="Nidhogg silver" />
                    </div>
                  )}
                  {row?.reward?.['usd'] > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                      <span>&nbsp;&nbsp;+</span>
                      <span>${row.reward['usd']}</span>
                      <img src={`${cdnUrl}/app/wallet/faith.svg`} width="25" alt="Medusa silver" />
                    </div>
                  )}
                </div>
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <TablePagination
      rowsPerPageOptions={[]}
      component="div"
      count={rows.length} // Total number of items
      rowsPerPage={rowsPerPage} // Items per page
      page={page} // Current page
      onPageChange={handleChangePage} // Handle page change
    />
    </>
  );
};

const App = ({ rows }) => {
  return <MyTable rows={rows} />;
};

export default App;
